<template>
  <div class="collapse navbar-collapse" id="second-navigation">
    <ul class="navbar-nav">
      <!-- Product Management -->
      <li
        :class="
          'nav-item nav-second' +
          (first.children.length ? ' dropdown has-megamenu' : '')
        "
        v-for="(first, key) in menuList({
          isCustomTheme,
          erpIntegration,
          marketplaceIntegration,
          eInvoiceIntegration,
          isActive
        }).main.filter((A) => A.is_active)"
        :key="key"
        v-if="checkPackagePermission(first.key)"
      >
        <router-link
          :to="first.to"
          class="nav-link p-3 dropdown-toggle"
          data-toggle="dropdown"
        >
          <i :class="first.icon"></i>
          <span class="ml-2">{{ $t(first.name) | toUpperCase }}</span>
        </router-link>

        <!-- Mega Menu -->
        <div
          class="dropdown-menu mega-menu shadow"
          v-if="first.children.length"
        >
          <div class="card-body">
            <div class="row">
              <!-- Product Section -->
              <div
                class="col-12 col-xl"
                v-for="second in first.children"
                :key="second.key"
                v-if="checkPackagePermission(second.key)"
              >
                <h6>
                  <router-link :to="second.to" class="text-dark no-decoration">
                    <i :class="second.icon"></i>
                    {{ $t(second.name) | toUpperCase }}
                  </router-link>
                </h6>
                <div class="dropdown-divider"></div>
                <ul
                  class="mega-list mb-3 mb-xl-0"
                  v-for="third in second.children"
                  :key="third.key"
                >
                  <li>
                    <router-link :to="third.to" class="btn btn-white">
                      <i :class="third.icon"></i>
                      <span class="ml-2">{{ $t(third.name) }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Mega Menu -->
      </li>
      <!-- ./Product Management -->
    </ul>
    <ul class="navbar-nav ml-md-auto">
      <!-- Settings -->
      <li
        class="nav-item nav-second dropdown"
        v-for="first in menuList({
          isCustomTheme,
          erpIntegration,
          marketplaceIntegration,
          eInvoiceIntegration,
          isActive
        }).setting.filter((A) => A.is_active)"
        :key="first.key"
      >
        <router-link
          to=""
          class="nav-link p-3 dropdown-toggle"
          data-toggle="dropdown"
        >
          <i class="fas fa-cogs"></i>
          <span class="ml-2">{{ $t("settings") | toUpperCase }}</span>
        </router-link>
        <div class="dropdown-menu dropdown-menu-right shadow font-14">
          <router-link
            :to="second.to"
            class="dropdown-item btn btn-white py-2"
            v-for="second in first.children.filter((A) => A.is_active)"
            :key="second.key"
            v-if="checkPackagePermission(second.key)"
          >
            <i :class="second.icon"></i>
            <span class="ml-2">{{ $t(second.name) }}</span>
          </router-link>
        </div>
      </li>
      <!-- ./Settings -->
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import menu from "../../../../../../../shared/data/menu";
export default {
  name: "Items",
  data() {
    return {
      menuList: menu,
    };
  },
  methods: {
    checkPackagePermission(key) {
      const permissions = this.getPackagePermission;
      if (permissions && Array.isArray(permissions)) {
        let permission = permissions.find((A) => A.key == key);
        if (permission) {
          return permission.status;
        }
      }
      return true;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    getPackagePermission() {
      return this.config["app.package_permission"];
    },

    marketplaceIntegration() {
      return (
        this.config["site.marketplace_integration"] == 1 ||
        this.config["site.marketplace_integration"] == "1"
      );
    },
    erpIntegration() {
      return (
        this.config["site.erp_integration"] == 1 ||
        this.config["site.erp_integration"] == "1"
      );
    },
    eInvoiceIntegration() {
      return (
        this.config["site.invoice_integration"] == 1 ||
        this.config["site.invoice_integration"] == "1"
      );
    },
    isActive() {
      return this.config['app.name_tenant'] == "ugursaat";
    },
    isCustomTheme() {
      if (this.config["app.custom_theme"]) {
        return this.config["app.custom_theme"] || 0;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    $(document).ready(function () {
      $("a.text-dark.no-decoration, a.btn.btn-white").on("click", function (e) {
        setTimeout(() => {
          $(".dropdown-menu").removeClass("show");
          $("#second-navigation").removeClass("show");
        }, 50);
      });
    });
  },
};
</script>