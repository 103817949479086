<template>
  <!-- Second Navbar -->
  <nav
    class="
      navbar navbar-expand-xl navbar-light
      bg-white
      shadow-sm
      border-bottom
      p-0
    "
  >
    <div class="col-12 p-0">
      <Toggler />
      <Content />
    </div>
  </nav>
  <!-- Second Navbar -->
</template>

<script>
import Toggler from "./components/Toggler/Index";
import Content from "./components/Content/Index";

export default {
  name: "Menu",
  components: {
    Toggler,
    Content,
  },
};
</script>

<style lang="scss">
.mega-menu {
  margin: 0 !important;
  z-index: 1030;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.02);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mega-list {
  margin: 0;
  padding: 0;
}

.mega-list li {
  display: block;
  list-style: none;
  // margin-bottom: 0.5rem;
}

.mega-list li a {
  padding: 10px 10px;
  display: block;
  text-align: left;
}

// .mega-list .btn:hover {
//   box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
// }

@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position: static !important;
  }

  .navbar .mega-menu {
    left: -7px;
    right: 0;
    width: 100%;
    padding: 20px;
  }
}

/* ----- NAVBAR ----- */

.nav-top {
  border-radius: 0.2rem;
  min-width: 40px;
  text-align: center;
}

.nav-top:hover {
  background-color: rgb(64, 64, 168);
}

.nav-top:hover .nav-link {
  color: #ffffff !important;
}

.nav-second {
  border-bottom: 3px solid transparent;
}

.nav-active .nav-link {
  font-weight: 600;
  color: #2f3542 !important;
}

.nav-active,
.nav-second:hover {
  border-bottom: 3px solid #ff4757;
  color: #2f3542 !important;
}

.nav-second:hover {
  background-color: #e6eaef !important;
}

.nav-text,
.nav-second span {
  font-size: 0.8rem !important;
}
/* ----- NAVBAR ----- */
</style>
