var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"second-navigation"}},[_c('ul',{staticClass:"navbar-nav"},_vm._l((_vm.menuList({
        isCustomTheme: _vm.isCustomTheme,
        erpIntegration: _vm.erpIntegration,
        marketplaceIntegration: _vm.marketplaceIntegration,
        eInvoiceIntegration: _vm.eInvoiceIntegration,
        isActive: _vm.isActive
      }).main.filter(function (A) { return A.is_active; })),function(first,key){return (_vm.checkPackagePermission(first.key))?_c('li',{key:key,class:'nav-item nav-second' +
        (first.children.length ? ' dropdown has-megamenu' : '')},[_c('router-link',{staticClass:"nav-link p-3 dropdown-toggle",attrs:{"to":first.to,"data-toggle":"dropdown"}},[_c('i',{class:first.icon}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.$t(first.name))))])]),(first.children.length)?_c('div',{staticClass:"dropdown-menu mega-menu shadow"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((first.children),function(second){return (_vm.checkPackagePermission(second.key))?_c('div',{key:second.key,staticClass:"col-12 col-xl"},[_c('h6',[_c('router-link',{staticClass:"text-dark no-decoration",attrs:{"to":second.to}},[_c('i',{class:second.icon}),_vm._v(" "+_vm._s(_vm._f("toUpperCase")(_vm.$t(second.name)))+" ")])],1),_c('div',{staticClass:"dropdown-divider"}),_vm._l((second.children),function(third){return _c('ul',{key:third.key,staticClass:"mega-list mb-3 mb-xl-0"},[_c('li',[_c('router-link',{staticClass:"btn btn-white",attrs:{"to":third.to}},[_c('i',{class:third.icon}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t(third.name)))])])],1)])})],2):_vm._e()}),0)])]):_vm._e()],1):_vm._e()}),0),_c('ul',{staticClass:"navbar-nav ml-md-auto"},_vm._l((_vm.menuList({
        isCustomTheme: _vm.isCustomTheme,
        erpIntegration: _vm.erpIntegration,
        marketplaceIntegration: _vm.marketplaceIntegration,
        eInvoiceIntegration: _vm.eInvoiceIntegration,
        isActive: _vm.isActive
      }).setting.filter(function (A) { return A.is_active; })),function(first){return _c('li',{key:first.key,staticClass:"nav-item nav-second dropdown"},[_c('router-link',{staticClass:"nav-link p-3 dropdown-toggle",attrs:{"to":"","data-toggle":"dropdown"}},[_c('i',{staticClass:"fas fa-cogs"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.$t("settings"))))])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right shadow font-14"},_vm._l((first.children.filter(function (A) { return A.is_active; })),function(second){return (_vm.checkPackagePermission(second.key))?_c('router-link',{key:second.key,staticClass:"dropdown-item btn btn-white py-2",attrs:{"to":second.to}},[_c('i',{class:second.icon}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t(second.name)))])]):_vm._e()}),1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }