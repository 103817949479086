<template>
  <!-- Second Toggler -->
  <div class="p-3 p-xl-0 text-center">
    <button
      class="navbar-toggler p-3 font-14"
      type="button"
      data-toggle="collapse"
      data-target="#second-navigation"
      aria-controls="second-navigation"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-route fa-lg"></i>
      <span class="ml-2">{{ $t("menu") | toUpperCase }}</span>
    </button>
  </div>
  <!-- ./Second Toggler -->
</template>

<script>
export default {
  name: "Toggler",
};
</script>