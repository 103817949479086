module.exports = (
  visibility = {
    isCustomTheme: false,
    erpIntegration: false,
    marketplaceIntegration: false,
    eInvoiceIntegration: false,
    isActive: false
  }
) => {
  return {
    main: [
      {
        name: "cockpit",
        key: "cockpit",
        icon: "fas fa-home",
        to: "/",
        is_active: true,
        children: [],
      },
      {
        name: "product",
        key: "product_management",
        icon: "fas fa-cube",
        to: "",
        is_active: true,
        children: [
          {
            name: "products",
            key: "product_title",
            icon: "fas fa-boxes",
            to: "products",
            children: [
              {
                name: "addNewProduct",
                key: "add_new_product",
                icon: "fas fa-plus",
                to: "/products/new",
                children: [],
              },
              {
                name: "productList",
                key: "product_list",
                icon: "fas fa-bars",
                to: "/products",
                children: [],
              },
              {
                name: "packageManagement",
                key: "package_management",
                icon: "fas fa-box",
                to: "/packages",
                children: [],
              },
              {
                name: "equipmentManagement",
                key: "equipment_management",
                icon: "fas fa-cubes",
                to: "/equipments",
                children: [],
              },
              {
                name: "showcaseManagement",
                key: "showcase_management",
                icon: "fas fa-th-large",
                to: "/showcase-management",
                children: [],
              },
            ],
          },
          {
            name: "categories",
            key: "categories_title",
            icon: "fas fa-stream",
            to: "/categories",
            children: [
              {
                name: "addNewCategory",
                key: "add_new_category",
                icon: "fas fa-plus",
                to: "/categories/new",
                children: [],
              },
              {
                name: "categoryList",
                key: "category_list",
                icon: "fas fa-bars",
                to: "/categories",
                children: [],
              },
            ],
          },
          {
            name: "brands",
            key: "brands_title",
            icon: "far fa-copyright",
            to: "/brands",
            children: [
              {
                name: "addNewBrand",
                key: "add_new_brand",
                icon: "fas fa-plus",
                to: "/brands/new",
                children: [],
              },
              {
                name: "brandList",
                key: "brand_list",
                icon: "fas fa-bars",
                to: "/brands",
                children: [],
              },
              {
                name: "addNewBrandType",
                key: "add_new_brand_type",
                icon: "fas fa-plus",
                to: "/brand-types/new",
                children: [],
              },
              {
                name: "brandTypeList",
                key: "brand_type_list",
                icon: "fas fa-bars",
                to: "/brand-types",
                children: [],
              },
            ],
          },
          {
            name: "models",
            key: "models_title",
            icon: "fas fa-code-branch",
            to: "/models",
            children: [
              {
                name: "addNewModel",
                key: "add_new_model",
                icon: "fas fa-plus",
                to: "/models/new",
                children: [],
              },
              {
                name: "modelList",
                key: "model_list",
                icon: "fas fa-bars",
                to: "/models",
                children: [],
              },
            ],
          },
          {
            name: "features",
            key: "feature_title",
            icon: "fas fa-network-wired",
            to: "/features",
            children: [
              {
                name: "addNewFeature",
                key: "add_new_feature",
                icon: "fas fa-plus",
                to: "/features/new",
                children: [],
              },
              {
                name: "featureList",
                key: "feature_list",
                icon: "fas fa-bars",
                to: "/features",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "order",
        key: "order_management",
        icon: "fas fa-shopping-basket",
        to: "",
        is_active: true,
        children: [
          {
            name: "orders",
            key: "order_title",
            icon: "fas fa-shopping-basket",
            to: "/orders",
            children: [
              {
                name: "addNewOrder",
                key: "add_new_order",
                icon: "fas fa-plus",
                to: "/orders/new",
                children: [],
              },
              {
                name: "orderList",
                key: "order_list",
                icon: "fas fa-bars",
                to: "/orders",
                children: [],
              },
              {
                name: "incorrectOrderList",
                key: "incorrect_order_list",
                icon: "fas fa-times-circle",
                to: "/failed-orders",
                children: [],
              },
            ],
          },
          {
            name: "cargoManagement",
            key: "cargo_title",
            icon: "fas fa-truck",
            to: "/shipping-companies",
            children: [
              {
                name: "addNewCargoCompany",
                key: "add_new_cargo_company",
                icon: "fas fa-plus",
                to: "/shipping-companies/new",
                children: [],
              },
              {
                name: "cargoCompanyList",
                key: "cargo_company_list",
                icon: "fas fa-bars",
                to: "/shipping-companies",
                children: [],
              },
              {
                name: "deliveryZonesHours",
                key: "delivery_zones_hours",
                icon: "fas fa-globe",
                to: "/delivery-zones",
                children: [],
              },
            ],
          },
          {
            name: "cargoInsuranceManagement",
            key: "cargo_insurance_title",
            icon: "fas fa-truck",
            to: "/cargo-insurances",
            children: [
              {
                name: "cargoInsuranceCompanies",
                key: "cargo_insurance_company_list",
                icon: "fas fa-check",
                to: "/cargo-insurances",
                children: [],
              },
            ],
          },
          {
            name: "serviceRegions",
            key: "service_regions_title",
            icon: "fas fa-globe",
            to: "/service-regions",
            children: [
              {
                name: "countries", //Ülkeler
                key: "region_country_list",
                icon: "fas fa-bars",
                to: "/service-regions",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "customer",
        key: "customer_management",
        icon: "fas fa-users",
        to: "",
        is_active: true,
        children: [
          {
            name: "customers",
            key: "customer_title",
            icon: "fas fa-users",
            to: "/members",
            children: [
              {
                name: "addNewCustomer",
                key: "add_new_customer",
                icon: "fas fa-plus",
                to: "/members/new",
                children: [],
              },
              {
                name: "customerList",
                key: "customer_list",
                icon: "fas fa-bars",
                to: "/members",
                children: [],
              },
            ],
          },
          {
            name: "memberType",
            key: "member_type_title",
            icon: "fas fa-user-shield",
            to: "/member-types",
            children: [
              {
                name: "addNewMemberType",
                key: "add_new_member_type",
                icon: "fas fa-plus",
                to: "/member-types/new",
                children: [],
              },
              {
                name: "memberTypeList",
                key: "member_type_list",
                icon: "fas fa-bars",
                to: "/member-types",
                children: [],
              },
            ],
          },
          {
            name: "memberGroup",
            key: "member_group_title",
            icon: "fas fa-user-friends",
            to: "/member-groups",
            children: [
              {
                name: "addNewGroup",
                key: "add_new_member_group",
                icon: "fas fa-plus",
                to: "/member-groups/new",
                children: [],
              },
              {
                name: "memberGroupList",
                key: "member_group_list",
                icon: "fas fa-bars",
                to: "/member-groups",
                children: [],
              },
            ],
          },
          {
            name: "customerComments",
            key: "customer_comments_title",
            icon: "fas fa-comments",
            to: "/comments",
            children: [
              {
                name: "customerCommentList",
                key: "customer_comment_list",
                icon: "fas fa-bars",
                to: "/comments",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "campaign",
        key: "campaign_management",
        icon: "fas fa-calendar-alt",
        to: "",
        is_active: true,
        children: [
          {
            name: "campaigns",
            key: "campaign_title",
            icon: "fas fa-calendar-alt",
            to: "/campaigns",
            children: [
              {
                name: "addNewCampaign",
                key: "add_new_campaign",
                icon: "fas fa-plus",
                to: "/campaigns/new",
                children: [],
              },
              {
                name: "campaignList",
                key: "campaign_list",
                icon: "fas fa-bars",
                to: "/campaigns",
                children: [],
              },
            ],
          },
          {
            name: "coupons",
            key: "coupon_title",
            icon: "fas fa-tags",
            to: "/coupons",
            children: [
              {
                name: "addNewCoupon",
                key: "add_new_coupon",
                icon: "fas fa-plus",
                to: "/coupons/new",
                children: [],
              },
              {
                name: "couponList",
                key: "coupon_list",
                icon: "fas fa-bars",
                to: "/coupons",
                children: [],
              },
            ],
          },
          {
            name: "bulkActions",
            key: "bulk_action_title",
            icon: "fas fa-users",
            to: "",
            children: [
              {
                name: "emailTemplates",
                key: "email_templates",
                icon: "fas fa-palette",
                to: "/email-templates",
                children: [],
              },
              {
                name: "sendEmail",
                key: "send_email",
                icon: "fas fa-envelope",
                to: "/bulk-actions/email",
                children: [],
              },
              {
                name: "emailList",
                key: "email_list",
                icon: "fas fa-users",
                to: "/email-lists",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "content",
        key: "content_management",
        icon: "fas fa-align-center",
        to: "",
        is_active: true,
        children: [
          {
            name: "contractPages",
            key: "contract_title",
            icon: "fas fa-pencil-alt",
            to: "/contracts",
            children: [
              {
                name: "addNewContract",
                key: "add_new_contract",
                icon: "fas fa-plus",
                to: "/contracts/new",
                children: [],
              },
              {
                name: "contractList",
                key: "contract_list",
                icon: "fas fa-bars",
                to: "/contracts",
                children: [],
              },
            ],
          },
          {
            name: "staticPages",
            key: "static_page_title",
            icon: "fas fa-pencil-alt",
            to: "/contracts",
            children: [
              {
                name: "addNewPage",
                key: "add_new_page",
                icon: "fas fa-plus",
                to: "/pages/new",
                children: [],
              },
              {
                name: "pageList",
                key: "page_list",
                icon: "fas fa-bars",
                to: "/pages",
                children: [],
              },
            ],
          },
          {
            name: "blog",
            key: "blog_title",
            icon: "fas fa-file-alt",
            to: "/blogs",
            children: [
              {
                name: "addNewBlog",
                key: "add_new_blog",
                icon: "fas fa-plus",
                to: "/blogs/new",
                children: [],
              },
              {
                name: "blogList",
                key: "blog_list",
                icon: "fas fa-bars",
                to: "/blogs",
                children: [],
              },
            ],
          },
          {
            name: "sliders",
            key: "slider_title",
            icon: "fas fa-exchange-alt",
            to: "/sliders",
            children: [
              {
                name: "addNewSlider",
                key: "add_new_slider",
                icon: "fas fa-plus",
                to: "/sliders/new",
                children: [],
              },
              {
                name: "sliderList",
                key: "slider_list",
                icon: "fas fa-bars",
                to: "/sliders",
                children: [],
              },
            ],
          },
          {
            name: "banners",
            key: "banner_title",
            icon: "fas fa-images",
            to: "/banners",
            children: [
              {
                name: "addBanner",
                key: "add_new_banner",
                icon: "fas fa-plus",
                to: "/banners/new",
                children: [],
              },
              {
                name: "bannerList",
                key: "banner_list",
                icon: "fas fa-bars",
                to: "/banners",
                children: [],
              },
            ],
          },
          {
            name: "shops",
            key: "shop_title",
            icon: "fas fa-store-alt",
            to: "/shops",
            children: [
              {
                name: "addShop",
                key: "add_new_shop",
                icon: "fas fa-plus",
                to: "/shops/new",
                children: [],
              },
              {
                name: "shopList",
                key: "shop_list",
                icon: "fas fa-bars",
                to: "/shops",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "interaction",
        key: "interaction_management",
        icon: "fas fa-wave-square",
        to: "",
        is_active: true,
        children: [
          {
            name: "siteTopTitle",
            key: "site_top_title",
            icon: "fas fa-angle-double-up",
            to: "",
            children: [
              {
                name: "siteTopTitles",
                key: "top_title_list",
                icon: "fas fa-bars",
                to: "/top-titles",
                children: [],
              },
            ],
          },
          {
            name: "popUpManagement",
            key: "popup_title",
            icon: "fas fa-expand",
            to: "",
            children: [
              {
                name: "addNewPopUp",
                key: "add_new_popup",
                icon: "fas fa-plus",
                to: "/pop-ups/new",
                children: [],
              },
              {
                name: "popUpList",
                key: "popup_list",
                icon: "fas fa-bars",
                to: "/pop-ups",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "user",
        key: "user_management",
        icon: "fas fa-user-shield",
        to: "",
        is_active: true,
        children: [
          {
            name: "users",
            key: "user_title",
            icon: "fas fa-users",
            to: "/users",
            children: [
              {
                name: "addNewUser",
                key: "add_new_user",
                icon: "fas fa-plus",
                to: "/users/new",
                children: [],
              },
              {
                name: "userList",
                key: "user_list",
                icon: "fas fa-bars",
                to: "/users",
                children: [],
              },
            ],
          },
          {
            name: "userRoles",
            key: "user_role_title",
            icon: "fas fa-user-check",
            to: "/roles",
            children: [
              {
                name: "addNewRole",
                key: "add_new_role",
                icon: "fas fa-plus",
                to: "/roles/new",
                children: [],
              },
              {
                name: "roleList",
                key: "role_list",
                icon: "fas fa-bars",
                to: "/roles",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "seo",
        key: "seo_management",
        icon: "fas fa-rocket",
        to: "",
        is_active: true,
        children: [
          {
            name: "bulkSeoOperations",
            key: "bulk_seo_title",
            icon: "fas fa-rocket",
            to: "",
            children: [
              {
                name: "brandSeoUpdate",
                key: "brand_seo_update",
                icon: "far fa-copyright",
                to: "/seo-update/brand",
                children: [],
              },
              {
                name: "categorySeoUpdate",
                key: "category_seo_update",
                icon: "fas fa-stream",
                to: "/seo-update/category",
                children: [],
              },
              {
                name: "productSeoUpdate",
                key: "product_seo_update",
                icon: "fas fa-cube",
                to: "/seo-update/product",
                children: [],
              },
              {
                name: "pageSeoUpdate",
                key: "page_seo_update",
                icon: "fas fa-scroll",
                to: "/seo-update/page",
                children: [],
              },
              {
                name: "blogSeoUpdate",
                key: "blog_seo_update",
                icon: "far fa-file-alt ml-1",
                to: "/seo-update/blog",
                children: [],
              },
              {
                name: "equipmentSeoUpdate",
                key: "equipment_seo_update",
                icon: "fas fa-cubes ml-1",
                to: "/seo-update/equipment",
                children: [],
              },
              {
                name: "HomeSeoUpdate",
                key: "home_seo_update",
                icon: "fas fa-home ml-1",
                to: "/seo-update/home",
                children: [],
              },
            ],
          },
          {
            name: "linkManagement",
            key: "link_management_title",
            icon: "fas fa-link",
            to: "/error-pages",
            children: [],
          },
        ],
      },
      {
        name: "reports",
        key: "report_management",
        icon: "fas fa-chart-line",
        to: "",
        is_active: true,
        children: [
          {
            name: "order",
            key: "order_report_title",
            icon: "fas fa-shopping-basket",
            to: "",
            children: [
              {
                name: "dailyOrderReports",
                key: "daily_order_report",
                icon: "far fa-dot-circle",
                to: "/daily-order-reports",
                children: [],
              },
              {
                name: "orderReports",
                key: "order_report",
                icon: "far fa-dot-circle",
                to: "/order-reports",
                children: [],
              },
            ],
          },
          {
            name: "product",
            key: "product_report_title",
            icon: "fas fa-shopping-basket",
            to: "",
            children: [
              {
                name: "productSalesReports",
                key: "product_sale_report",
                icon: "far fa-dot-circle",
                to: "/product-reports/sale",
                children: [],
              },
              {
                name: "cancelReturnReports",
                key: "cancel_return_report",
                icon: "far fa-dot-circle",
                to: "/product-reports/returns-cancels",
                children: [],
              },
              {
                name: "stockNotificationReports",
                key: "stock_notification_report",
                icon: "far fa-dot-circle",
                to: "/product-reports/stock-notifications",
                children: [],
              },
            ],
          },
          {
            name: "campaign",
            key: "campaign_report_title",
            icon: "fas fa-calendar-alt",
            to: "",
            children: [
              {
                name: "campaignReports",
                key: "campaign_report",
                icon: "far fa-dot-circle",
                to: "/campaign-reports",
                children: [],
              },
              {
                name: "discountCouponReports",
                key: "discount_coupon_report",
                icon: "far fa-dot-circle",
                to: "/campaign-reports/discount-coupons",
                children: [],
              },
            ],
          },
          {
            name: "member",
            key: "member_report_title",
            icon: "fas fa-calendar-alt",
            to: "",
            children: [
              {
                name: "memberReports",
                key: "member_report",
                icon: "far fa-dot-circle",
                to: "/member-reports",
                children: [],
              },
              {
                name: "memberSaleReports",
                key: "member_sale_report",
                icon: "far fa-dot-circle",
                to: "/member-reports/sale",
                children: [],
              },
              {
                name: "memberCancelReturnReports",
                key: "member_cancel_return_report",
                icon: "far fa-dot-circle",
                to: "/member-reports/returns-cancels",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "suppliers",
        key: "supplier_management",
        icon: "fa fa-inbox",
        to: "",
        is_active: true,
        children: [
          {
            name: "supplierIntegration",
            key: "supplier_integration_title",
            icon: "fa fa-industry",
            to: "",
            children: [
              {
                name: "addNewSupplierIntegration",
                key: "add_new_supplier_integration",
                icon: "fas fa-plus",
                to: "/supplier-integration/new",
                children: [],
              },
              {
                name: "supplierIntegrationList",
                key: "supplier_integration_list",
                icon: "fas fa-stream",
                to: "/supplier-integration",
                children: [],
              },
              {
                name: "supplierCategoryMatching",
                key: "supplier_category_matching",
                icon: "fas fa-wave-square",
                to: "/supplier-integration/category",
                children: [],
              },
              {
                name: "supplierReductionAndProfit",
                key: "supplier_reduction_and_profit",
                icon: "fa fa-percent",
                to: "/supplier-integration/reduction-and-profit",
                children: [],
              },
              {
                name: "supplierFilter",
                key: "supplier_filter",
                icon: "fa fa-filter",
                to: "/supplier-integration/filters",
                children: [],
              },
            ],
          },
          {
            name: "xmlTemplate",
            key: "xml_template_title",
            icon: "fa fa-palette",
            to: "",
            children: [
              {
                name: "xmlTemplateList",
                key: "xml_template_list",
                icon: "fas fa-stream",
                to: "/xml-template",
                children: [],
              },
              {
                name: "addNewXmlTemplate",
                key: "add_new-xml_template",
                icon: "fas fa-plus",
                to: "/xml-template/new",
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: "imports",
        key: "import_data",
        icon: "fa fa-upload",
        to: "/import-data",
        is_active: visibility.isActive,
        children: []
      }
    ],
    setting: [
      {
        name: "settings",
        key: "setting_manager",
        icon: "fas fa-cogs",
        is_active: true,
        to: "",
        children: [
          {
            name: "generalSettings",
            key: "general_setting",
            icon: "fas fa-cogs",
            to: "/settings",
            is_active: true,
            children: [],
          },
          {
            name: "themeSetting",
            key: "theme_setting",
            icon: "fas fa-palette",
            to: "/themes",
            is_active: !visibility.isCustomTheme,
            children: [],
          },
          {
            name: "translationSettings",
            key: "translation_setting",
            icon: "fas fa-language",
            to: "/translation-settings",
            is_active: true,
            children: [],
          },
          {
            name: "collectionSetings",
            key: "payment_setting",
            icon: "fas fa-calculator",
            to: "/payments",
            is_active: true,
            children: [],
          },
          {
            name: "erpIntegrationSettings",
            key: "erp_integration_setting",
            icon: "fas fa-square-root-alt",
            to: "/erp",
            is_active: visibility.erpIntegration,
            children: [],
          },
          {
            name: "marketplaceIntegrationSettings",
            key: "marketplace_setting",
            icon: "fas fa-shopping-cart",
            to: "/marketplaces",
            is_active: visibility.marketplaceIntegration,
            children: [],
          },
          {
            name: "eInvoiceIntegrationSettings",
            key: "e_invoice_setting",
            icon: "fas fa-receipt",
            to: "/e-invoice-integrations",
            is_active: visibility.eInvoiceIntegration,
            children: [],
          },
        ],
      },
    ],
  };
};
